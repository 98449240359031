<template>
  <div class="landing-page">
    <header id="header" class="header d-flex align-items-center fixed-top">
      <div class="container-fluid container-xl d-flex align-items-center">
        <a href="/landingPage" class="logo d-flex align-items-center me-auto">
          <img src="/assets/img/logo.png" alt="" />
          <h1 class="sitename">İş Asistan</h1>
        </a>
        <nav id="navmenu" class="navmenu">
          <ul>
            <li><a href="#anasayfa" class="active">{{$t("landingPage.menu.home") }}</a></li>
            <li><a href="#asistan">{{$t("landingPage.menu.assistant") }}</a></li>
          </ul>
          <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>
        <router-link to="login"
          ><a class="btn-getstarted">{{$t("landingPage.menu.login") }}</a></router-link
        >
      </div>
    </header>

    <main class="main">
      <section id="hero" class="hero section">
        <div class="container">
          <div class="row gy-4">
            <div
              class="
                col-lg-6
                order-2 order-lg-1
                d-flex
                flex-column
                justify-content-center
              "
            >
              <h1>{{$t("landingPage.headerBanner.title") }}</h1>
              <p>
                {{$t("landingPage.headerBanner.desc") }}
              </p>
              <a href="#asistan" class="btn-get-started"
                >AI İş Asistanı<i class="bi bi-arrow-right"></i
              ></a>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 hero-img">
              <img
                src="/media/landing/header.png"
                class="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="asistan" class="about section">
        <div class="container">
          <div class="row gx-0">
            <div class="col-lg-6 d-flex align-items-center">
              <img src="/media/landing/header2.png" class="img-fluid" alt="" />
            </div>
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <div class="content">
                <h3>                {{$t("landingPage.container.mainTitle") }}</h3>
                <h2>{{$t("landingPage.container.mainHeader") }}</h2>
                <p>
                  {{$t("landingPage.container.mainDesc") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="values" class="values section">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-4">
              <div class="card">
                <img
                  src="/media/landing/yapay-zeka-ikon2.png"
                  class="img-fluid"
                  alt=""
                />
                <h3> {{$t("landingPage.container.title") }}</h3>
                <p>
                  {{$t("landingPage.container.desc") }}
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <img
                  src="/media/landing/yapay-zeka-ikon3.png"
                  class="img-fluid"
                  alt=""
                />
                <h3>{{$t("landingPage.container.title2") }}</h3>
                <p>
                  {{$t("landingPage.container.desc2") }}
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <img
                  src="/media/landing/yapay-zeka-ikon.png"
                  class="img-fluid"
                  alt=""
                />
                <h3>{{$t("landingPage.container.title3") }}</h3>
                <p>
                  {{$t("landingPage.container.desc3") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer id="footer" class="footer">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-6 footer-about">
            <a href="landingPage" class="d-flex align-items-center">
              <span class="sitename">OGZA Teknoloji A.Ş.</span>
            </a>
            <p>
              Çifte Havuzlar, Yıldız Teknopark, A1 Blok, 34220 İkitelli
              Osb/Esenler/İstanbul
            </p>
            <p><strong>E-posta:</strong> destek@ogzatech.com</p>
          </div>

          <div class="col-lg-3 col-md-3 footer-links">
            <h4>Kısayollar</h4>
            <ul>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="https://www.ogzatech.com/">Ogzatech</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="#anasayfa">Anasayfa</a>
              </li>
              <li>
                <i class="bi bi-chevron-right"></i>
                <a href="#asistan">İş Asistan</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-12">
            <h4>Bizi Takip Et</h4>
            <p></p>
            <div class="social-links d-flex">
              <a href="https://x.com/ogzatech"><i class="bi bi-twitter"></i></a>
              <a href="https://www.facebook.com/ogzatech/"
                ><i class="bi bi-facebook"></i
              ></a>
              <a href="https://www.instagram.com/ogzatech/"
                ><i class="bi bi-instagram"></i
              ></a>
              <a
                href="https://www.linkedin.com/company/ogzatech/?originalSubdomain=tr"
                ><i class="bi bi-linkedin"></i
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div class="container text-center mt-4">
        <p>&copy; Ogzatech, Tüm hakları saklıdır.</p>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
/*
@import "assets/vendor/bootstrap/js/bootstrap.bundle.min.js";
@import "assets/vendor/php-email-form/validate.js";
@import "assets/vendor/aos/aos.js";
@import "assets/vendor/glightbox/js/glightbox.min.js";
@import "assets/vendor/purecounter/purecounter_vanilla.js";
@import "assets/vendor/imagesloaded/imagesloaded.pkgd.min.js";
@import "assets/vendor/isotope-layout/isotope.pkgd.min.js";
@import "assets/vendor/swiper/swiper-bundle.min.js";
@import "assets/js/main.js";
*/
@import "assets/sass/plugins";
@import "assets/sass/style";

@import "assets/vendor/bootstrap/css/bootstrap.min.css";
@import "assets/vendor/bootstrap-icons/bootstrap-icons.css";
@import "assets/vendor/aos/aos.css";
@import "assets/vendor/glightbox/css/glightbox.min.css";
@import "assets/vendor/swiper/swiper-bundle.min.css";
@import "assets/css/main.css";
</style>

<script>
export default {
  name: "LandingPage",
  mounted() {},
};
</script>
